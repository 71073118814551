import React, {useState} from 'react';
import {getCoursorPointer} from "../cursor/Cursor";
import {useNavigate} from "react-router";

const BtnOutline = (props) => {
    const [ripOut, setRipOut] = useState(false);
    const [btnMove, setTnMove] = useState({ x: 0, y: 0 });
    let pointer = getCoursorPointer();
    const navigate = useNavigate();

    const onMove = (e) => {
        if (!ripOut) {
            setTnMove({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
        }
    }

    const mouseEnter = (e) => {
        setRipOut(true);

        if(pointer) {
            pointer.style.width = "150px";
            pointer.style.height = "150px";
            pointer.style.transitionDuration = "0.2s";
        }
    }

    const mouseLeave = (e) => {
        setRipOut(false);
        if(pointer) {
            pointer.style.width = "50px";
            pointer.style.height = "50px";
            // pointer.style.transitionDuration = "0s";
        }
    }

    let onClick = props.onClick;
    if(props.to){
        onClick = ()=>{
            if(props.onClick) props.onClick();
            navigate(props.to);
        };
    }

    return (
        <button
            onClick={onClick}
            type={props.type}
            className={"btn btnFull "+props.className}
            onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}
            onMouseMove={onMove}
        >
            {ripOut? <span style={{left: btnMove.x+"px", top: btnMove.y+"px"}} className="ripple bg-green"/>:""}
            <span className={"text"}>{props.children}</span>
        </button>
    );
};

export default BtnOutline;
