import React, {useEffect, useRef, useState} from 'react';

const ReCaptcha = (props)=>{
    const element = useRef();
    const [token, setToken] = useState('');
    let timer;

    const renderCaptcha = ()=>{
        if(window.grecaptcha && typeof window.grecaptcha.render === 'function') {
            if(timer) clearTimeout(timer);
            window.grecaptcha.render(element.current, {
                'sitekey': process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
                'theme': 'dark',
                'callback': setToken,
            });
        } else {
            setTimeout(renderCaptcha, 500);
        }
    }

    useEffect(()=>{
        timer = setTimeout(renderCaptcha, 500)
    }, []);

    const style = {
        width: '0',
        height: '0',
        opacity: '0',
        position: 'absolute',
        top: '0',
    }

    return (
        <div className="recaptcha-container position-relative">
            <input style={style} type="text" name="g-recaptcha-token" value={token} required />
            <div ref={element} className="recaptcha mb-3"></div>
        </div>
    );
}

export default ReCaptcha;
