import React from 'react';
import {Link} from "react-router-dom";
import "./bred.scss";

const Breadcrumbs = (props) => {
    return (
        <ul className="breadcrumbs">
            <li className="breadcrumbs-li">
                <Link to="/" className="breadcrumbs-link">Главная</Link>
            </li>

            {(props.links||[]).map((link, i) =>
                <li key={i} className="breadcrumbs-li">
                    <Link to={link.link} className="breadcrumbs-link">{link.name}</Link>
                </li>
            )}

            <li className="breadcrumbs-li">{props.title}</li>
        </ul>
    );
};

export default Breadcrumbs;
