import {get} from "../utils/request";
import {GlobalState} from "../utils/store";

let isLoading = false;
export const reviewsStore = new GlobalState([]);

export const getReviews = ()=>{
    if(isLoading) return;
    isLoading = true;
    get(
        '/reviews',
        function (response){
            isLoading = false;
            reviewsStore.setState(response);
        }
    )
}
