import {call} from "../utils/functions";
import {getCache, setCache} from "../utils/cache";
import {yandexMetricNumber} from "./analytics";

export const events = {
    formSend: ()=>{
        call(()=>{
            window.gtag('event', 'formsend', {
                'event_category' : 'form'
            });
        });
        call(()=>{
            window.ym(yandexMetricNumber,'reachGoal','SEND_FORM');
        })
    },
    clickMessenger: ()=>{
        call(()=>{
            window.gtag('event', 'messege', {
                'event_category' : 'messege'
            });
        });
        call(()=>{
            window.ym(yandexMetricNumber,'reachGoal','MESSENGER');
        })
    },
    clickPhone: ()=>{
        call(()=>{
            window.gtag('event', 'phoneclick', {
                'event_category' : 'phoneclick'
            });
        });
        call(()=>{
            window.ym(90311478,'reachGoal','CLICK_PHONE');
        })
    }
}

export const utmStorage = {
    set: (params)=>{
        setCache('utm_params', params);
    },
    get: ()=>{
        return getCache('utm_params');
    }
}
