import React from 'react';
import LinkFull from "../animations/LinkFull";
import {isMobile} from "../../utils/util";

const PersonCard = (props) => {
    const pers = props.pers;

    return (
        <div className="personCard">
            <div className="person">
                <div className="person-image">
                    <img src={pers.avatar} alt=""/>
                </div>
                <div className="panel-inf">
                    <div className="panel-inf-head">
                        <div className="radial" style={{backgroundColor: "#78E26E"}}/>
                        <div className="radial" style={{backgroundColor: "#E2C96E"}}/>
                        <div className="radial" style={{backgroundColor: "#E26E6E"}}/>
                    </div>
                    <div className="name">{pers.fullname}</div>
                    <div className="desc">{pers.shortDesc}</div>
                </div>
            </div>
            <div className="person-inf">
                <div>
                    <div className="fz20 mb-2 fw-Gilroy-700">Чем занимается:</div>
                    <div className="fz20 mb-35 opacity-7">{pers.description}</div>
                </div>
                <div>
                    <div className="fz20 mb-2 fw-Gilroy-700">Опыт:</div>
                    {pers.experience.map((text, i) =>
                        <div key={i} className="fz20 mb-3 quotation">{text}</div>
                    )}
                </div>
                {!isMobile?
                    <div>
                        <div className="fz20 mb-2 fw-Gilroy-700">Используемые инструменты:</div>
                        {pers.technologies?
                            <div  className="tools-img">
                                {pers.technologies.map((tech, i) =>
                                    <img key={i} src={tech.icon} alt=""/>
                                )}
                            </div>
                            :""
                        }
                    </div>
                :""}

                {/*<LinkFull to="/" className="btn btn-success btn-lg col-12">Получить консульацию</LinkFull>*/}
            </div>
        </div>
    );
};

export default PersonCard;
