import React, {useState} from 'react';
import Viewer from 'react-viewer';
import './image-preview.scss';

const data = {
    setActive: null,
    setImages: null,
}

export const viewImages = (images, activeIndex)=>{
    data.setImages(images.map(i=>({src: i, alt: ''})));
    data.setActive(activeIndex);
}

const ImagePreview = (props)=>{
    const [images, setImages] = useState([]);
    const [activeIndex, setActive] = useState(0);
    data.setImages = setImages;
    data.setActive = setActive;

    if(!images.length) return '';

    return (
        <Viewer
            visible={true}
            rotatable={false}
            scalable={false}
            noImgDetails={true}
            zoomSpeed={0.5}
            images={images}
            activeIndex={activeIndex}
            onClose={()=>{setImages([])}}
        />
    );
}

export default ImagePreview;
