import React from 'react';

const ContactsSvgAnimation = () => {
    return (
        <div className="text">
            <svg viewBox="0 0" width="100%" height="100%">
                <use xlinkHref="#contacts-text" className="text-body"/>
                <use xlinkHref="#contacts-text" className="text-copy"/>
                <use xlinkHref="#contacts-text" className="text-copy"/>
                <use xlinkHref="#contacts-text" className="text-copy"/>

                <symbol id="contacts-text">
                    <text text-ancor="start" dominantBaseline="text-before-edge">Contacts • Contacts • Contacts • Contacts •</text>
                </symbol>
            </svg>
        </div>
    );
};

export default ContactsSvgAnimation;