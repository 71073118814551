import projectLogoStanzia from "../images/logo/stancia-logo.svg";
import projectLogoBiznes from "../images/logo/business-tour-logo2.svg";
import projectLogoMandarin from "../images/logo/mandarin-logo.svg";
import projectLogotoponimiTatarstan from "../images/logo/toponym-logo.svg";
import projectLogoEco from "../images/logo/ecoLogo.png";
import projectLogoOnber from "../images/logo/onberLogo.png";
import projectLogoPanda from "../images/logo/pandaLogo.png";
import projectLogoMiras from "../images/logo/miras-logo.svg";
import {get} from "../utils/request";
import {GlobalState} from "../utils/store";

let isLoading = false;
export const projectsStore = new GlobalState([]);

export const getProjects = ()=>{
    if(isLoading) return;
    isLoading = true;
    get(
        '/projects',
        function (response){
            isLoading = false;
            projectsStore.setState(response);
        }
    )
}

export const getProject = (projectId, callback)=>{
    const projects = projectsStore.getState();
    if(projects.length) return callback(projects.find(p => p.id == projectId)||{});

    get(
        '/projects/'+projectId,
        function (response){
            callback(response);
        }
    )
}

export const categoriesStore = new GlobalState([]);

export const getCategories = ()=>{
    get(
        '/categories',
        function (response){
            categoriesStore.setState(response);
        }
    )
}

export const sectionNames = {
    sectionNameApp: "Каждое мобильное приложение - это",
    sectionNameSite: "Каждый сайт - это"
}

export const projects = [
    { key: 1, image: projectLogoStanzia, class: "bg1" },
    { key: 2, image: projectLogoBiznes, class: "bg2" },
    { key: 3, image: projectLogotoponimiTatarstan, class: "bg1" },
    { key: 4, image: projectLogoPanda, class: "bg2" },
    { key: 5, image: projectLogoEco, class: "bg2" },
    { key: 6, image: projectLogoOnber, class: "bg1" },
    { key: 7, image: projectLogoMandarin, class: "bg2" },
    { key: 8, image: projectLogoMiras, class: "bg1" },
]

export const commentsSite = [
    {
        nameCompany: "Сайт «Эволюционер»",

        customerName: "?",
        customerFeedback: "Попросить написать человека отзыв",
        image: '',
    }
]
