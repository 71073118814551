import React, { useState } from 'react';
import logoImage from "../../images/logomain.png";
import { Link } from "react-router-dom";
import "./header.scss"
import Hamburger from "./Hamburger";
import { RiWhatsappFill } from "@react-icons/all-files/ri/RiWhatsappFill";
import { FaTelegramPlane } from "@react-icons/all-files/fa/FaTelegramPlane";
import Menu from "../menu/Menu";
import BtnOutline from "../animations/BtnOutline";
import HistoryScroll from '../HistoryScroll';
import {config} from "../../query/config";
import {events} from "../../services/events";

const Header = (props) => {
    const [openMenu, setOpenMenu] = useState(false);
    function hamActive() {
        setOpenMenu(!openMenu);
    }

    return (
        <>
            <HistoryScroll />
            <header className={"header " + props.className}>
                <div className="container d-flex align-items-center">
                    <Link to="/" className="logo">
                        <img src={logoImage} alt="" />
                    </Link>
                    <div className="d-inline-flex align-items-center ms-auto me-auto">
                        <div className="me-3 discuss-project">У вас есть свой проект?</div>
                        <BtnOutline to="/brief" className="btn-outline-success">Обсудить проект</BtnOutline>
                    </div>
                    <div className="">
                        <a
                            onClick={events.clickPhone}
                            href={"tel:"+config.phone} className="position-relative fz20 text-white mb-1 text-decoration-none expandedBlack"
                        >{config.phone}</a>
                        <div className="point point-green">
                            <span className="me-1">Звоните!</span>
                            Консультация бесплатная
                        </div>
                    </div>
                    <div className="socseti-wrap">
                        <div className="text">Связаться с нами в мессенджерах:</div>
                        <div className="socseti">
                            <a onClick={events.clickMessenger} href={config.tg.link} target="_blank" className="link telega">
                                <FaTelegramPlane className="text-white fz18" />
                            </a>
                            <a onClick={events.clickMessenger} href={config.whatsap} target="_blank" className="link whatsap">
                                <RiWhatsappFill className="text-white fz18" />
                            </a>
                        </div>
                    </div>
                    <Hamburger openMenu={openMenu} setOpenMenu={setOpenMenu} onClick={hamActive} />
                </div>
            </header>
            {openMenu ? <Menu openMenu={openMenu} setOpenMenu={setOpenMenu} /> : ""}
        </>
    );
};

export default Header;
