import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {getCoursorPointer} from "../cursor/Cursor";

const LinkFull = (props) => {
    const [ripLink, setRipLink] = useState(false);
    const [btnMove, setTnMove] = useState({ x: 0, y: 0 });
    let pointer = getCoursorPointer();
    const onMove = (e) => {
        if (!ripLink) {
            setTnMove({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
        }
    }

    const mouseEnter = (e) => {
        setRipLink(true);
        if(pointer) {
            pointer.style.width = "150px";
            pointer.style.height = "150px";
            pointer.style.transitionDuration = "0.2s";
        }
    }
    const mouseLeave = (e) => {
        setRipLink(false);
        if(pointer) {
            pointer.style.width = "50px";
            pointer.style.height = "50px";
            // pointer.style.transitionDuration = "0s";
        }
    }
    return (
        <Link to={props.to} type={props.type}
              className={"btn btnFull "+props.className}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
              onMouseMove={onMove}
        >
            {ripLink? <span style={{left: btnMove.x+"px", top: btnMove.y+"px"}} className="ripple bg-white"/>:""}
            <span className={"text"}>{props.children}</span>
        </Link>
    );
};

export default LinkFull;