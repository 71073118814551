import React, {useEffect, useState} from 'react';
import { SiYoutube } from "@react-icons/all-files/si/SiYoutube";
import { Link } from "react-router-dom";
import avatar from "../../images/profile.png";
import ScrollAnimation from "react-animate-on-scroll";
import frameSvg from "../../images/mobilOtzivi.svg";
import {getProject} from "../../query/cases";


const Review = (props) => {
    let review = props.review;
    const [project, setProject] = useState({solution:[],images:[]});
    const [view, setView] = useState(false);

    useEffect(()=>{
        if(review.project_id) {
            getProject(review.project_id, setProject);
        }
    }, [review.project_id]);

    let image = project.images.find(img=> img.type==='app' || img.type==='mobile') || {};

    const viewAll = (e)=>{
        e.preventDefault();
        setView(!view);
    }

    return (
        <div className="comment-section">
            <div className="left">
                <ScrollAnimation animateOnce={true} animateIn="animate__fadeInLeft">
                    <div className="fz20 text-white mb-30 opacity-5">
                        Нашими услугами воспользовались более 60 компаний малого и среднего бизнеса
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateOnce={true} animateIn="animate__fadeInLeft">
                    <div className="panel-inf">
                        <div className="panel-inf-head">
                            <div className="radial" style={{ backgroundColor: "#78E26E" }} />
                            <div className="radial" style={{ backgroundColor: "#E2C96E" }} />
                            <div className="radial" style={{ backgroundColor: "#E26E6E" }} />
                        </div>
                        <h4 className="mb-30 text-white expandedBlack">
                            {project.name}
                        </h4>
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <button className="text-green text-green-button" onClick={props.prev}>
                                « Предыдущий отзыв</button>
                            <button className="text-green text-green-button" onClick={props.next}>
                                Следующий отзыв »</button>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="right">
                <div className="cmment-wrap">
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                        <div className="fz20 fw-Gilroy-700 text-white mb-20">Пожелания заказчика</div>
                        <div className="fz16 text-white opacity-7 mb-40">{project.task}</div>
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                        <div className="fz20 fw-Gilroy-700 text-white mb-20">Наша реализация</div>
                        <div className="quotation fz16 text-white opacity-7 mb-3">{project.solution[0]}</div>
                        {project.solution[1] ? <div className="quotation fz16 text-white opacity-7 mb-3">{project.solution[1]}</div> : ""}
                        {project.solution[2] ?<div className="quotation fz16 text-white opacity-7 mb-3">{project.solution[2]}</div> : ""}
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                        <div className="cmmentBox">
                            {/* <div className="men">
                                <img src={review.image || avatar} alt="" />
                            </div> */}
                            <div className="mb-3 fz20 fw-Gilroy-700 ">{review.author}</div>
                            {review.link ?
                                <Link to="/" className="mb-3 text-blue link-youtube">
                                    <SiYoutube className="me-2" />Смотреть видеоотзыв
                                </Link>
                            :""}
                            <div className="text" style={{WebkitLineClamp: !view ? 4 : 1000}}>{review.description}</div>
                            <a href="#" onClick={viewAll} className="text-blue fz16">{!view ? "Читать далее" : "Свернуть"}</a>
                        </div>
                    </ScrollAnimation>
                </div>
                <div className="commentImage">
                    <img src={frameSvg} alt="" className="frame"/>
                    <img src={image.path} alt="" className="intoFrame"/>
                </div>

            </div>
        </div>
    );
};

export default Review;
