import React, {useEffect, useState} from 'react';
import PanelMenu from "../../components/menu/PanelMenu";
import Header from "../../components/header/Header";
import TextFon from "../../components/fon/TextFon";
import ServSvg1 from "../../components/services/ServSvg1";
import Quality from "../../components/quality/Quality";
import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";
import { quality } from "../../query/data";
import "./application.scss";
import Person from "../../components/person/Person";
import AppBox from "../../components/appBox/AppBox";
import Approaches from "../../components/approaches/Approaches";
import Projects from "../../components/project/Projects";
import Job from "../../components/job/Job";
import Footer from "../../components/footer/Footer";
import Team from "../../components/teamSection/Team";
import Reviews from '../../components/reviews/Reviews';
import Faq from "../../components/faq/Faq";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import { useNavigate } from "react-router";
import { ReactComponent as PackIcon } from "../../images/pack-icon.svg";
import {appBox} from "../../query/development";
import {getProjects, projectsStore, sectionNames} from "../../query/cases";
import {useSubscribe} from "../../utils/store";
import LinkFull from "../../components/animations/LinkFull";
import {isMobile} from "../../utils/util";

const Application = () => {
    const projects = projectsStore.getState();
    useSubscribe(projectsStore);

    useEffect(() => {
        if(!projects.length) getProjects();
    }, []);

    const [active, setActive] = useState(false);

    function funActive() {
        setActive(!active);
    }
    const [anim, setAnim] = useState(false);
    function onEnter() {
        setAnim(true);
    }
    function onLeave() {
        setAnim(false)
    }

    return (
        <div className="position-relative page">
            <PanelMenu />
            <Header className="mr-130" />
            <div className="application-title">
                {!isMobile ? <TextFon/> : ""}
                <div className="container mt-auto mb-auto">
                    <h1 className="text-white expandedBlack mb-50">
                        Разработка <br /> мобильных <br /> приложений
                    </h1>
                    <LinkFull to="/cases" className="btn-success btn-project btn-lg">
                        <PackIcon />
                        Смотреть проекты
                    </LinkFull>
                    <ServSvg1 className="application-svg" />
                </div>
                <div className="container mt-auto">
                    <a href="#jfodpsdokgdsfg" className="text-decoration-none text-gray cursor-pointer d-inline-flex align-items-center">
                        Узнать больше
                        <IoIosArrowDown className="fz18 ms-1" />
                    </a>
                </div>
            </div>
            <div id="jfodpsdokgdsfg" className="quality-section">
                <div className="container">
                    <div className="qualitys-wrap">
                        <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                            <h2 className="text-white expandedBlack mb-60 col-12">
                                Почему выгодно сотрудничать именно с нами
                            </h2>
                        </ScrollAnimation>
                        <div className="qualitys">
                            {quality.map((q) =>
                                <Quality key={q.key} q={q} />
                            )}
                        </div>
                    </div>
                    <Person />
                </div>
            </div>
            <div className="platform-section">
                <div className="container">
                    <div className="left">
                        <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                            <h2 className="expandedBlack mb-60">
                                Разрабатываем мобильные приложения на основных
                                <span className="text-blue"> платформах iOS и Android</span>
                            </h2>
                        </ScrollAnimation>
                        <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                            <div className="panel-inf">
                                <div className="panel-inf-head">
                                    <div className="radial" style={{ backgroundColor: "#78E26E" }} />
                                    <div className="radial" style={{ backgroundColor: "#E2C96E" }} />
                                    <div className="radial" style={{ backgroundColor: "#E26E6E" }} />
                                </div>
                                <div className="fz16 opacity-5">
                                    Мы создаем выразительные и многофункциональные нативные приложения для iOS/Android, кросс-платформенные и прогрессивные веб-приложения на базе проверенных топовых технологий.
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="right">
                        {appBox.map((box) =>
                            <AppBox key={box.key} box={box} />
                        )}
                    </div>
                </div>
            </div>
            <div className="approach-section">
                <div className="container">
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                        <h2 className="mb40 expandedBlack text-white">
                            Оцените <span className="text-green"> наш подход </span>
                            при создании мобильного приложения
                        </h2>
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                        <div className="approach-filter">
                            <div className="text">
                                Одно мобильное приложение - это командная разработка, длинною от 1 до 6 месяцев
                            </div>
                            <div className="approach-filter-panel">
                                <div className={active ? "btn btn-sm active" : "btn btn-sm "} onClick={funActive}>
                                    Кратко
                                </div>
                                <div className={active ? "btn btn-sm" : "btn btn-sm active"}
                                    onClick={funActive}>
                                    Подробно
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <Approaches active={active} />
                </div>
            </div>
            <div className="project-section" onMouseEnter={onEnter} onMouseLeave={onLeave}>
                <div className="container">
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                        <h2 className="mb-60 expandedBlack text-white">Наши проекты</h2>
                    </ScrollAnimation>
                </div>
                <Projects anim={anim} setAnim={setAnim} projects={projects.filter(p=>!!p.images.find(img=>img.type==='app'))} isApp={true}/>
            </div>
            <div className="job-section">
                <Job />
            </div>
            <Team />
            <Reviews name={sectionNames.sectionNameApp} type="app" />
            <Faq />
            <Footer />


        </div>
    );
};

export default Application;
