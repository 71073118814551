import React from 'react';
import personImg from "../../images/person.jpg";
import "./person.scss";

const Person = () => {
    return (
        <div className="person">
            <div className="person-image">
                <img src={personImg} alt=""/>
            </div>
            <div className="panel-inf">
                <div className="panel-inf-head">
                    <div className="radial" style={{backgroundColor: "#78E26E"}}/>
                    <div className="radial" style={{backgroundColor: "#E2C96E"}}/>
                    <div className="radial" style={{backgroundColor: "#E26E6E"}}/>
                </div>
                <div className="name">Смирнов Павел</div>
                <div className="desc">Директор агентства «WIT»</div>
            </div>
        </div>
    );
};

export default Person;