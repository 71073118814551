export const chunk = (array, step) => {
    const res = [];
    for (let i = 0; i < array.length; i += step) {
        const chunk = array.slice(i, i + step);
        res.push(chunk);
    }
    return res;
}

export const inArray = (string, array)=>{
    return array.indexOf(string) >= 0;
}
