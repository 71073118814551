import {useEffect, useState} from "react";

export function GlobalState(initialValue) {
    this.value = initialValue;
    this.subscribers = [];

    this.getState = function () {
        return this.value;
    }

    this.setState = function (newState) {
        if (this.getState() === newState) {
            return
        }

        this.value = newState;
        this.subscribers.forEach(subscriber => {
            subscriber(this.value);
        });
    }

    this.subscribe = function (itemToSubscribe) {
        if (this.subscribers.indexOf(itemToSubscribe) > -1) {
            return
        }
        this.subscribers.push(itemToSubscribe);
    }

    this.unsubscribe = function (itemToUnsubscribe) {
        this.subscribers = this.subscribers.filter(
            subscriber => subscriber !== itemToUnsubscribe
        );
    }
}

export function useSubscribe(store) {
    const [, setState] = useState();

    function reRender() {
        setState({});
    }

    useEffect(() => {
        store.subscribe(reRender);

        return () => {
            store.unsubscribe(reRender);
        }
    });

    return store.getState();
}
