import React, {useEffect, useState} from 'react';
import PanelMenu, {toUpStore} from "../../components/menu/PanelMenu";
import TextFon from "../../components/fon/TextFon";
import Header from "../../components/header/Header";
import {IoIosArrowDown} from "@react-icons/all-files/io/IoIosArrowDown";
import Projects from "../../components/project/Projects";
import {Swiper, SwiperSlide} from "swiper/react";
import { Mousewheel} from "swiper";
import "swiper/css";
import Services from "../../components/services/Services";
import Job from "../../components/job/Job";
import ContactsFooter from "../../components/footer/ContactsFooter";
import "./main.scss";
import BtnFull from "../../components/animations/BtnFull";
import {ReactComponent as PackIcon} from '../../images/pack-icon.svg';
import Team from "../../components/teamSection/Team";
import {getProjects, projectsStore} from "../../query/cases";
import {useSubscribe} from "../../utils/store";
import {isMobile, mobileScrollToTarget} from "../../utils/util";
import LinkFull from "../../components/animations/LinkFull";
import {geoStore} from "../../stores/geo";
import {cityIn} from "lvovich";

const slider = {
    call: null
}

export const slideTo = (index)=> {
    slider.call(index);
}

const Main = () => {
    const geo = useSubscribe(geoStore);
    const projects = projectsStore.getState().slice(0,5);
    useSubscribe(projectsStore);
    useEffect(()=>{
        if(!projects.length) getProjects();
    },[]);

    const [swiperRef, setSwiperRef] = useState(null);

    const slideTo = (index) => {
        if(isMobile) mobileScrollToTarget(document.getElementById("main-"+index));
        swiperRef.slideTo(index - 1, 400);
    };

    const [anim, setAnim] = useState(false);
    function onEnter() {
        setAnim(true);
    }
    function onLeave() {
        setAnim(false)
    }

    slider.call = slideTo;

    const changeSlide = (s)=>{
        if(s.activeIndex > 0){
            toUpStore.setState(()=>{
                slideTo(0);
            })
        } else {
            toUpStore.setState(false)
        }
    }

    return (
        <div className="position-relative page pageMain">

            <PanelMenu/>
            <Swiper
                direction={"vertical"}
                slidesPerView={1}
                speed={800}
                spaceBetween={12}
                mousewheel={true}
                modules={[ Mousewheel ]}
                className="mySwiper"
                onSwiper={setSwiperRef}
                breakpoints={{
                    0: {
                        enabled: false,
                        spaceBetween: 0
                    },
                    1000: {
                        slidesPerView: 1,
                    },
                }}
                onSlideChange={changeSlide}
            >
                <SwiperSlide id="main-1" className="screen-one">
                    {!isMobile ? <TextFon/> : ""}
                    <Header/>
                    <div className="container mb-40">
                        <h1 className="text-white expandedBlack">
                            Разработка сайтов и мобильных приложений для среднего и крупного бизнеса {geo.city ? "в " + cityIn(geo.city) : ""}
                        </h1>
                        <LinkFull to="/cases" className="btn-project btn-success btn-lg">
                            <PackIcon />
                            Смотреть проекты
                        </LinkFull>
                    </div>
                    {!isMobile ?
                        <div className="container">
                            <div onClick={() => slideTo(2)} className="text-gray cursor-pointer d-inline-flex align-items-center">
                                Узнать больше <IoIosArrowDown className="fz18 ms-1"/>
                            </div>
                        </div>
                    :""}
                </SwiperSlide>
                <SwiperSlide id="main-2" onMouseEnter={onEnter} onMouseLeave={onLeave} className="screen-two ">
                    <Projects anim={anim} setAnim={setAnim} projects={projects}/>
                </SwiperSlide>
                <SwiperSlide id="main-3" className="screen-three">
                    <Services/>
                </SwiperSlide>
                <SwiperSlide id="main-4" className="screen-four">
                    <Job/>
                </SwiperSlide>
                <SwiperSlide id="main-5" className="screen-team">
                    <Team/>
                </SwiperSlide>
                <SwiperSlide id="main-6" className="screen-five">
                    <ContactsFooter/>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Main;
