import React from 'react';
import personImg from "../../images/person.jpg";
import {Accordion} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import {faq} from "../../query/faq";
import {openCallback} from "../../pages/modal/Callback";
import BtnOutline from "../animations/BtnOutline";
import {briefStore} from "../../stores/brief";

const Faq = () => {

    return (
        <div className="faq-section">
            <div className="container">
                <div className="left">
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                        <h2 className="text-white mb-40 expandedBlack">
                            «Мы знаем, какие вопросы задают наши клиенты»
                        </h2>
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                        <div className="panel-inf">
                            <div className="panel-inf-head">
                                <div className="radial" style={{backgroundColor: "#78E26E"}}/>
                                <div className="radial" style={{backgroundColor: "#E2C96E"}}/>
                                <div className="radial" style={{backgroundColor: "#E26E6E"}}/>
                            </div>
                            <div className="men">
                                <img src={personImg} alt=""/>
                            </div>
                            <div className="men-inf">
                                <div className="desc">
                                    Отвечает директор компании Смирнов Павел
                                </div>
                            </div>
                            <BtnOutline onClick={()=>{briefStore.setState("min")}} className="btn btn-success btn-lg col-12">
                                Задать вопрос
                            </BtnOutline>
                        </div>
                    </ScrollAnimation>
                </div>
                <div className="right">
                    <Accordion defaultActiveKey="0">
                        {faq.map((faq, index) => (
                            <Accordion.Item key={faq.key} eventKey={index}>
                                <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                                    <Accordion.Header>{faq.question}</Accordion.Header>
                                    <Accordion.Body>{faq.answer}</Accordion.Body>
                                </ScrollAnimation>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default Faq;
