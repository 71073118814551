import React from 'react';
import "./textFon.scss";
import SvgAnimation from "../svg/SvgAnimation";
const TextFon = () => {
    return (
        <div className="text-fon">
            <div className="text">
                <SvgAnimation/>
            </div>
            <div className="text">
                <SvgAnimation/>
            </div>
            <div className="text">
                <SvgAnimation/>
            </div>
        </div>
    );
};

export default TextFon;