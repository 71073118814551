import React from 'react';
import {FaTelegramPlane} from "@react-icons/all-files/fa/FaTelegramPlane";
import {RiWhatsappFill} from "@react-icons/all-files/ri/RiWhatsappFill";
import "./contactsFooter.scss";
import {Link} from "react-router-dom";
import BtnOutline from "../animations/BtnOutline";
import ContactsSvgAnimation from "../svg/ContactsSvgAnimation";
import {config} from "../../query/config";
import {openCallback} from "../../pages/modal/Callback";
import {isMobile} from "../../utils/util";
import {events} from "../../services/events";

const ContactsFooter = () => {
    return (
        <div className="contacts">
            {!isMobile ?
                <div className="text-fon">
                    <ContactsSvgAnimation  />
                    <ContactsSvgAnimation  />
                    <ContactsSvgAnimation  />
                </div>
            :""}
            <div className="container">
                <div className="mb-50">
                    <h2 className="h1 expandedBlack">Обсудить проект</h2>
                    <div className="text-white fz18">Доверьте веб-разработку профессионалам</div>
                </div>
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="col">
                    <div className="fz18 text-white mb-3">позвоните:</div>
                        <a onClick={events.clickPhone} href={"tel:"+config.phone} className="fz48 text-decoration-none text-green mb-1">{config.phone}</a>
                        <div className="fz16 text-white mb-50 opacity-5">Нажмите, чтобы позвонить</div>
                        <div className="fz18 text-white mb-3">или напишите:</div>
                        <div className="col d-flex mb-20">
                            <a
                                onClick={events.clickMessenger}
                                href={config.whatsap}
                                target="_blank"
                                className="text-decoration-none text-green fz24 me-5"
                            ><RiWhatsappFill className="me-3"/>WhatsApp</a>
                            <a
                                onClick={events.clickMessenger}
                                href={config.tg.link}
                                target="_blank"
                                className="text-decoration-none text-green fz24"
                            ><FaTelegramPlane className="me-3"/>Telegram</a>
                        </div>
                        <div className="fz15 text-white opacity-5">Среднее время  ответа 15 минут</div>
                    </div>
                    <div className="col inf">
                        <div className="">
                            <div className="fz16 mb-20 text-white">Перезвоним в течение 15 минут</div>
                            <BtnOutline onClick={openCallback} className="btn-outline-success mb-50 col-12">Заказать звонок</BtnOutline>
                        </div>
                        <div className="">
                            <div className="text-white opacity-5">ИП {config.organization.name}</div>
                            <div className="text-white opacity-5">ОГРИП {config.organization.ogrip}</div>
                            <div className="text-white opacity-5 ">ИНН {config.organization.inn}</div>
                            <div className="text-white opacity-5 mb-30">{config.organization.address}</div>
                        </div>
                        <div className="">
                            <Link to="/politic" className="text-green fz12 text-decoration-none" target="_blank">Политика конфиденциальности</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ContactsFooter;
