import {post} from "../utils/request";
import {events, utmStorage} from "../services/events";

export const createAppeal = (data, callback) => {
    data.set('utm', JSON.stringify(utmStorage.get()))
    post(
        '/appeals',
        data,
        (response) => {
            events.formSend();
            if(callback) callback(response);
        }
    )
}
