import skype from "../images/skype.png";
import zoom from "../images/zoom.png";
import whatsap from "../images/whatsapp.png";
import telegram from "../images/telegram.png";

export const quality = [
    {
        key: 1,
        name: "C нами надежно",
        desc: "Более 200 успешно реализиванных проектов"
    },
    {
        key: 2,
        name: "Понимание задачи",
        desc: "Глубоко вникаем в проект и ведем до нужного результата"
    },
    {
        key: 3,
        name: "Всегда онлайн",
        desc: "На связи 24/7"
    },
    {
        key: 4,
        name: "Гибкий подход",
        desc: "Все виды оплаты/валюты"
    },
    {
        key: 5,
        name: "Быстрый результат",
        desc: "Первые результаты уже через 14 дней"
    },
    {
        key: 6,
        name: "C нами комфортно",
        desc: "Опыт работы в самых разных бизнес-нишах"
    },
]

export const resultSection = [
    {
        key: 1,
        count: "01",
        name: "Продать продукт",
        desc: "Создадим сайт с продуманной модульной структурой и стильным дизайном для продажи продуктов любых категорий или одного уникального товара."
    },
    {
        key: 2,
        count: "02",
        name: "Повысить продажи",
        desc: "Создадим онлайн-магазин с каталогом товаров, корзиной, доставкой, платежной системой и возможностью обратной связи."
    },
    {
        key: 3,
        count: "03",
        name: "Оформить услуги",
        desc: "Создадим персональный или корпоративный веб-сайт с понятной навигацией и клиентоориентированной логикой."
    },
]

export const tabNav = [
    { key: 1, eventKey: "company", num: "01", name: "Анализ компании", },
    { key: 2, eventKey: "competitor", num: "02", name: "Анализ конкурентов", },
    { key: 3, eventKey: "ca", num: "03", name: "Анализ ЦА", },
]

export const tabContent = [
    {
        key: 1,
        name: "Анализ компании",
        eventKey: "company",
        result: "Определена цель компании.",
        quotation: [
            "Изучаем бизнес-логику, которую компания хочет реализовать, и ожидания по результатам.",
            "Знакомимся с актуальными задачами, предлагаем гипотетическое решение и формируем цель для будущего сайта.",
        ]
    },
    {
        key: 2,
        name: "Анализ конкурентов",
        eventKey: "competitor",
        result: "Получен положительный опыт реализации похожих бизнес-задач.",
        quotation: [
            "Определяем, какие компании лидируют на рынке в нашей бизнес-нише на сегодняшний день.",
            "Анализируем подход к реализации бизнес-логики в онлайн-формате. Изучаем сильные и слабые стороны сайтов, интегрируем данные в систему.",
            "Выстраиваем логику нашего сайта: учитываем положительный опыт конкурентов, исключаем негативные сценарии, предлагаем новое решение задач.",
        ]
    },
    {
        key: 3,
        name: "Анализ целевой аудитории",
        eventKey: "ca",
        result: "Сформирован портрет нашего клиента.",
        quotation: [
            "Определяем социально демографические, психологические и поведенческие характеристики ЦА.",
            "Анализируем потребности, возражения, страхи, цели, которые влияют на решение покупки в интернете.",
            "Изучаем информационное поле для понимания, что видит клиент в браузере перед тем, как сформирует свой поисковой запрос.",
            "Сегментируем целевую аудиторию и ориентируемся на подходящих нам клиентов при построении логики сайта.",
        ]
    }
]

export const communications = [
    skype,zoom,whatsap,telegram,
]

