import React, {useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import FormControl from "../../components/form/FormControl";
import {Link} from "react-router-dom";
import "./modal.scss";
import BtnFull from "../../components/animations/BtnFull";
import {GlobalState, useSubscribe} from "../../utils/store";
import {createAppeal} from "../../query/appeal";
import Success from "./Success";
import Button from "react-bootstrap/Button";
import ReCaptcha from "../../components/form/ReCaptcha";
import TelephoneInput from "../../components/form/TelephoneInput";

export const callbackStore = new GlobalState();

export const openCallback = ()=>{
    callbackStore.setState(true);
}

const Callback = (props) => {
    const [sended, setSended] = useState(false);
    const callback = callbackStore.getState();
    useSubscribe(callbackStore);

    const hide = ()=>{
        callbackStore.setState(null);
    }

    if(!callback) {
        return '';
    }

    const submit = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        createAppeal(data, (response)=>{
            if(response.status === 'error') return;
            setSended(true);
            setTimeout(()=>{
                setSended(false);
            }, 5000);
        });
    }

    if(sended) {
        return <Success
            title="Запрос отправлен!"
            onHide={hide}
        />
    }

    return (
        <Modal
            show={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={hide}
        >
            <Modal.Body>
                <Button className="btn-close btn" onClick={hide}>

                </Button>
                <h2 className="fz38 text-white expandedBlack mb-3">Оставьте ваш номер</h2>
                <div className="fz18 text-white opacity-5 mb-5">Укажите ваш номер и мы вам перезвоним!</div>
                <Form onSubmit={submit}>
                    <input type="hidden" name="description" value="Отправить заявку" />

                    <Form.Group className="mb-40 position-relative col-12 ">
                        <FormControl name="firstname" required />
                        <Form.Label className="placeholder-label">Ваше имя</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-40 position-relative col-12 ">
                        <TelephoneInput name="telephone" mask="+7 999 999 99 99" maskChar="" required />
                        <Form.Label className="placeholder-label">Введите свой номер телефона</Form.Label>
                    </Form.Group>

                    <ReCaptcha />

                    <BtnFull className="btn-success mb-3 btn-lg col-12">Отправить заявку</BtnFull>
                    <div className="fz12 text-gray">Нажимая кнопку, вы даете
                        <Link to="/politic" className="text-green" target="_blank"> согласие
                            на обработку</Link> персональных данных
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Callback;
