import React, {useState} from 'react';
import {createAppeal} from "../../query/appeal";
import {Form} from "react-bootstrap";
import FormControl from "../form/FormControl";
import BtnFull from "../animations/BtnFull";
import {Link} from "react-router-dom";
import ReCaptcha from "../form/ReCaptcha";
import TelephoneInput from "../form/TelephoneInput";

const CallbackFooter = ()=>{
    const [sended, setSended] = useState(false);

    const submit = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        createAppeal(data, (response)=>{
            if(response.status === 'error') return;
            setSended(true);
            setTimeout(()=>{
                setSended(false);
            }, 5000);
        });
    }

    if(sended) {
        return (
            <Form className="col-form">
                <h2 className="fz38 text-white expandedBlack mb-3">Запрос отправлен!</h2>
            </Form>
        );
    }

    return (
        <Form className="col-form" onSubmit={submit}>
            <input type="hidden" name="description" value="Записаться на консультацию" />

            <Form.Group className="mb-30 position-relative col-12" >
                <TelephoneInput name="telephone" mask="+7 999 999 99 99" maskChar="" id="footer-telephone" required/>
                <Form.Label htmlFor="footer-telephone" className="placeholder-label">Введите свой номер телефона</Form.Label>
            </Form.Group>

            <ReCaptcha />

            <BtnFull className="mb-4 btn-success btn-lg col-12">
                Записаться на консультацию
            </BtnFull>
            <div className="opacity-5 text-white mb-40">
                Нажимая кнопку, вы даете
                <Link to="/politic" target="_blank" className="text-green mx-1">
                    согласие на обработку
                </Link>
                персональных данных
            </div>
        </Form>
    );
}

export default CallbackFooter;
