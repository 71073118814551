import React from 'react';
import "./quality.scss";
import ScrollAnimation from "react-animate-on-scroll";

const Quality = (props) => {
    const q = props.q;
    return (
        <ScrollAnimation  animateOnce={true} animateIn="animate__fadeInUp" >
            <div className="quality">
                <div className="mb-20 fw-Gilroy-700 text-white fz20">{q.name}</div>
                <div className="fz16 text-white opacity-7">{q.desc}</div>
            </div>
        </ScrollAnimation>
    );
};

export default Quality;
