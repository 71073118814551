export const setCache = (key, value)=>{
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
}

export const getCache = (key)=>{
    let value = localStorage.getItem(key);
    try {
        return JSON.parse(value);
    } catch (e) {

    }
}
