import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {getCoursorPointer} from "../cursor/Cursor";

const LinkOutline = (props) => {
    const [ripLinkOut, setRipLinkOut] = useState(false);
    const [btnMove, setTnMove] = useState({ x: 0, y: 0 });
    let pointer = getCoursorPointer();
    const onMove = (e) => {
        if (!ripLinkOut) {
            setTnMove({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
        }
    }

    const mouseEnter = (e) => {
        setRipLinkOut(true);
        if(pointer) {
            pointer.style.width = "150px";
            pointer.style.height = "150px";
            pointer.style.transitionDuration = "0.2s";
        }
    }
    const mouseLeave = (e) => {
        setRipLinkOut(false);
        if(pointer) {
            pointer.style.width = "50px";
            pointer.style.height = "50px";
            // pointer.style.transitionDuration = "0s";
        }
    }

    if(props.href){
        return (
            <a
                href={props.href}
                type={props.type}
                className={"btn btnFull "+props.className}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                onMouseMove={onMove}
                onClick={props.onClick}
                target="_blank"
            >
                {ripLinkOut? <span style={{left: btnMove.x+"px", top: btnMove.y+"px"}} className="ripple bg-green"/>:""}
                <span className={"text"}>{props.children}</span>
            </a>
        );
    }

    return (
        <Link
            to={props.to}
            type={props.type}
            className={"btn btnFull "+props.className}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onMouseMove={onMove}
            onClick={props.onClick}
            target="_blank"

        >
            {ripLinkOut? <span style={{left: btnMove.x+"px", top: btnMove.y+"px"}} className="ripple bg-green"/>:""}
            <span className={"text"}>{props.children}</span>
        </Link>
    );
};

export default LinkOutline;
