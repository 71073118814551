import React, {useRef, useState} from 'react';
import chainImg from "../../images/chain.svg";

const File = (props)=>{
    const {label, ...newProps} = props;
    const files = useRef();
    const [file, setFile] = useState({});

    if(props.elementRef) {
        props.elementRef.current = file;
    }

    const filesChange = (e)=>{
        setFile(e.target.files[0]);
    }

    return (
        <label className="file">
            <input
                {...newProps}
                ref={files}
                onChange={filesChange}
                type="file"
                className="inpFile"
            />
            <img src={chainImg} alt=""/>
            {file.name || label || "Прикрепить файлы"}
        </label>
    );
}

export default File;
