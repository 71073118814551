import {GlobalState} from "../utils/store";
import {post} from "../utils/request";

export const geoStore = new GlobalState({});

export const getGeo = (callback) => {
    post(
        '/config',
        {},
        (response) => {
            geoStore.setState(response.city ? response : {city: "Казань"});
        }
    )
}

