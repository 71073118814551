import React from 'react';
import PanelMenu from "../../components/menu/PanelMenu";
import Header from "../../components/header/Header";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import ContactsFooter from "../../components/footer/ContactsFooter";
import "./brief.scss";

const NotFound = () => {
    return (
        <div className="position-relative page briefPage">
            <PanelMenu/>
            <Header className="mr-130"/>
            <div className="container">
                <Breadcrumbs title="Страница не найдена!"/>
                <div className="pt-5 text-center">
                    <h1 className="expandedBlack text-white" >Страница не найдена!</h1>
                </div>
            </div>

            <div className="footerBref">
                <ContactsFooter/>
            </div>
        </div>
    );
};

export default NotFound;
