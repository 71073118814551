import React from 'react';
import "./footer.scss";
import {Link} from "react-router-dom";
import {RiWhatsappFill} from "@react-icons/all-files/ri/RiWhatsappFill";
import {FaTelegramPlane} from "@react-icons/all-files/fa/FaTelegramPlane";
import BtnOutline from "../animations/BtnOutline";
import ScrollAnimation from "react-animate-on-scroll";
import {config} from "../../query/config";
import {openCallback} from "../../pages/modal/Callback";
import CallbackFooter from "./CallbackFooter";
import {events} from "../../services/events";

const Footer = (props) => {
    return (
        <div>
            <footer className="footer">
                <div className="footer-make">
                    <div className="footer-make-col">
                        <div className="col-12">
                            <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                                <h2 className="text-white expandedBlack mb-20">
                                    Давайте начнем работу над вашим проектом
                                </h2>
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                                <div className="fz18 text-white mb-60">
                                    с простой бесплатной консультации
                                </div>
                            </ScrollAnimation>
                        </div>
                        <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                            <CallbackFooter />
                        </ScrollAnimation>
                        <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                            <div className="col d-flex  flex-wrap">
                                <div className="fz18 text-white mb-30 col-12">или напишите нам:</div>
                                <a onClick={events.clickMessenger} href={config.whatsap} target="_blank" className="text-decoration-none text-green fz24 me-5">
                                    <RiWhatsappFill className="me-3"/>WhatsApp
                                </a>
                                <a onClick={events.clickMessenger} href={config.tg.link} target="_blank" className="text-decoration-none text-green fz24 mb-20">
                                    <FaTelegramPlane className="me-3"/>Telegram
                                </a>
                                <div className="text-white opacity-5 col-12">
                                    Среднее время ответа 15 минут
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="footer-inf">
                    <div className="footer-inf-col">
                        <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                            <div className="fz20 mb-40 fw-Gilroy-700 text-white">
                                Что будет на консультации:
                            </div>
                        </ScrollAnimation>
                        <ul className="step">
                            <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                                <li>
                                    Выясним ваши ожидания: для чего и зачем вам нужен конкретный продукт
                                </li>
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                                <li>
                                    Предложим стратегию решения поставленных бизнес-задач
                                </li>
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                                <li>
                                    Определим сроки выполнения работы
                                </li>
                            </ScrollAnimation>
                        </ul>
                        <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                            <div className="col inf">
                                <div className="">
                                    <div className="fz16 mb-20 text-white">Перезвоним в течение 15 минут</div>
                                    <BtnOutline onClick={openCallback} className="btn-outline-success mb-50 col-12">
                                        Заказать звонок
                                    </BtnOutline>
                                </div>
                                <div className="mb-30">
                                    <div className="text-white opacity-5">ИП {config.organization.name}</div>
                                    <div className="text-white opacity-5">ОГРИП {config.organization.ogrip}</div>
                                    <div className="text-white opacity-5 ">ИНН {config.organization.inn}</div>
                                </div>
                                <div className="">
                                    <Link to="/politic" className="text-green fz12 text-decoration-none" target="_blank">
                                        Политика конфиденциальности
                                    </Link>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
