import React, {useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import FormControl from "../../components/form/FormControl";
import {Link} from "react-router-dom";
import "./modal.scss";
import BtnFull from "../../components/animations/BtnFull";
import {useSubscribe} from "../../utils/store";
import {briefStore} from "../../stores/brief";
import Textarea from "../../components/form/Textarea";
import File from "../../components/form/File";
import {createAppeal} from "../../query/appeal";
import Success from "./Success";
import {AiOutlineClose} from "@react-icons/all-files/ai/AiOutlineClose";
import Button from "react-bootstrap/Button";
import ReCaptcha from "../../components/form/ReCaptcha";
import TelephoneInput from "../../components/form/TelephoneInput";

const BriefMax = (props) => {
    const [sended, setSended] = useState(false);
    const brief = briefStore.getState();
    useSubscribe(briefStore);

    const hide = ()=>{
        briefStore.setState(null);
    }

    if(brief !== 'max') {
        return '';
    }

    const submit = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        createAppeal(data, (response)=>{
            if(response.status === 'error') return;
            setSended(true);
            setTimeout(()=>{
                setSended(false);
            }, 5000);
        });
    }

    if(sended) {
        return <Success
            title="Запрос отправлен!"
            onHide={()=>{briefStore.setState(null)}}
        />
    }

    return (
        <Modal
            show={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={hide}
        >
            <Modal.Body>
                <Button className="btn-close btn" onClick={hide}>

                </Button>
                <h2 className="fz38 text-white expandedBlack mb-3">Заполнить подробный бриф</h2>
                <div className="fz18 text-white opacity-5 mb-5">Заполните заявку для обсуждения вашего проекта</div>
                <Form onSubmit={submit}>
                    <Form.Group className="mb-40 position-relative col-12 " >
                        <FormControl name="firstname" required />
                        <Form.Label className="placeholder-label">Напишите ваше имя</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-40 position-relative col-12 ">
                        <TelephoneInput name="telephone" mask="+7 999 999 99 99" maskChar="" required />
                        <Form.Label className="placeholder-label">Введите свой номер телефона</Form.Label>
                    </Form.Group>
                    <div className="mb-20 position-relative col-12">
                        <div className="fz16 mb-3 text-white">Что вам нужно?</div>
                        <label className="check" htmlFor="options-site">
                            <Form.Control id="options-site" name="options[site]" type="checkbox" className="inpCheck" value="1"/>
                            <span className="checkbox"/>
                            Разработка сайта
                        </label>
                        <label className="check" htmlFor="options-app">
                            <Form.Control id="options-app" name="options[app]" type="checkbox" className="inpCheck" value="1"/>
                            <span className="checkbox"/>
                            Разработка мобильного приложения
                        </label>
                    </div>
                    <Form.Group className="mb-40 position-relative col-12 ">
                        <FormControl name="business" required />
                        <Form.Label className="placeholder-label">Напишите направление бизнеса</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-40 position-relative col-12 ">
                        <Textarea name="description" className="form-control" required />
                        <Form.Label className="placeholder-label">Кратко опишите суть вашего проекта</Form.Label>
                    </Form.Group>
                    <div className="mb-40 position-relative col-12">
                        <File name="file" />
                    </div>

                    <ReCaptcha />

                    <BtnFull className="btn-success mb-3 btn-lg col-12">Отправить заявку</BtnFull>
                    <div className="fz12 text-gray">Нажимая кнопку, вы даете
                        <Link to="/politic" className="text-green" target="_blank"> согласие
                            на обработку</Link> персональных данных
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default BriefMax;
