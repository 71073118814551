export const resize = (img, width, height) =>{
    if(!img) return '';

    let image = img.match(/(.*)(\.[a-zA-Z]{3,4})$/);
    let path = image[1];
    let ext = image[2];

    img = path + '-' + width;
    if(height) img += 'x' + height;
    img += ext;
    return img;
}
