import React from 'react';
import "./svg.scss";

const SvgAnimation = () => {
    return (
        <svg viewBox="0 0" width="100%" height="100%">
            <use xlinkHref="#tech-text" className="text-body"/>
            <use xlinkHref="#tech-text" className="text-copy"/>
            <use xlinkHref="#tech-text" className="text-copy"/>
            <use xlinkHref="#tech-text" className="text-copy"/>

            <symbol id="tech-text">
                <text text-ancor="start" dominantBaseline="text-before-edge">WebIsTech & WebIsTech</text>
            </symbol>
        </svg>
    );
};

export default SvgAnimation;