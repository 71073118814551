import React from 'react';
import { Link } from "react-router-dom";
import "./approaches.scss";
import ScrollAnimation from "react-animate-on-scroll";
import {approach} from "../../query/development";
import ApproachItem2 from "./ApproachItem2";

const Approaches = (props) => {
    const active = props.active;
    return (
        <div className="approaches">
            {approach.map((item, i) =>
                <ApproachItem2 key={i} item={item} active={active} />
            )}
        </div>
    );
};

export default Approaches;
