import stage1 from "../images/img.jpg";
import stage2 from "../images/stage-2.png";
import stage3 from "../images/stage-3.png";
import stage4 from "../images/stage-4.png";
import stage5 from "../images/stage-1.png";

export const appBox = [
    {
        key: 1,
        title: "Разработка приложений",
        desc: "У нас есть весь необходимый опыт для создания мобильных приложений:",
        links: [
            {
                link: "/",
                name: "Разработка приложения для iPhone"
            },
            {
                link: "/",
                name: "Разработка приложения для iPad"
            },
            {
                link: "/",
                name: "Разработка приложения для Android"
            },
            {
                link: "/",
                name: "Разработка приложения для планшетов на Android"
            }
        ],
        technologies: [
            'java',
            'swift',
            'kotlin',
            'cocoapods',
            'reactjs',
            'ionic',
            'dart',
            'flutter',
        ]
    }
]

export const approach = [
    {
        key: 1,
        count: "01",
        title: "Обсуждение проекта",
        image: stage1,
        employee: "teamlead",
        link: "/",
        description: "Ведем конструктивный диалог с заказчиком, составляем бриф → изучаем референсы и пожелания → предлагаем оптимальное решение поставленной задачи с прозрачными этапами работы",
        quotation: [
            "Понятный заказчику, поэтапный план создания приложения с нуля до запуска в продакшен",
            "Законченное техническое задание для создания прототипа, дизайна, верстки и кода",
        ],
    },
    {
        key: 2,
        count: "02",
        title: "Прототипирование",
        image: stage2,
        employee: "prototype",
        description: "Проводим исследование бизнес-ниши → разрабатываем несколько вариантов структуры продукта, учитывая целевую аудиторию и специфику поставленных задач → согласовываем один из вариантов → упаковываем продукт в понятную логическую модель",
        quotation: [
            "Эргономичный интерфейс, нацеленный получать высокую конверсию и транслировать ценности компании",
            "Эффективная структура, отвечающая всем правилам usability",
        ],
    },
    {
        key: 3,
        count: "03",
        title: "Дизайн",
        image: stage3,
        employee: "design",
        description: "Изучаем пожелания заказчика → анализируем конкурентов и определяем портрет целевой аудитории → формируем экономически оправданный концепт дизайна → согласовываем дизайн → создаем итоговую стилистику в интуитивно понятном интерфейсе",
        quotation: [
            "Красота и гармония в каждой детали, нацеленная на расположение к себе клиента",
            "Запоминающаяся анимация и общая эстетика",
        ],
    },
    {
        key: 4,
        count: "04",
        title: "Программирование",
        image: stage4,
        employee: "backend",
        description: "Верстаем дизайн → реализуем функционал → адаптируем продукт под любые мобильные устройства → выстраиваем процесс работы с обратной связью на каждом этапе",
        quotation: [
            "Модульная верстка и актуальный стек технологий",
             "Масштабируемая архитектура и чистый код",
        ],
    },
    {
        key: 5,
        count: "05",
        title: "Тестирование и исправление ошибок",
        image: stage5,
        employee: "test",
        description: "Определяем работоспособность продукта на любом этапе его создания → утверждаем каждый этап, прошедший тестирование → собираем модули в единую систему и еще раз тестируем → исправляем ошибки и отдаем продукт в продакшен",
        quotation: [
            "Уверенность, что все задачи реализованы и работают без багов",
            "Приложение, которое полностью соответствует заявленному ТЗ",
        ],
    },
]
