import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import InputMask from "react-input-mask";
import "./form.scss";

const TelephoneInput = (props) => {
    const {name, required, onChange, value, className, type, ...newProps} = props;
    const [text, setValue] = useState(value || "");
    const [telephone, setTelephone] = useState('');

    function changeText(e) {
        e.preventDefault();
        let val = e.target.value;
        setValue(val);
        let tel = val.replace(/[^0-9]/g,'');

        if(tel.length === 11) {
            setTelephone(tel);
        } else {
            setTelephone('');
        }
    }

    const style = {
        opacity: 0,
        width: 0,
        height: 0,
        position: 'absolute',
    };

    return (
        <>
            <input style={style} name={name} required={required} value={telephone} />
            <InputMask
                defaultValue={text}
                value={text}
                onChange={changeText}
                className={className + " form-control " + (!text ? "" : " focus")}
                type={type || "text"}
                {...newProps}
            />
        </>
    );
};

export default TelephoneInput;
