import React, {useEffect, useRef, useState} from 'react';
import Hamburger from "../header/Hamburger";
import "./menu.scss";
import Menu from "./Menu";
import {slideTo} from "../../pages/main/Main";
import {useLocation, useNavigate} from "react-router";
import BriefMin from "../../pages/modal/BriefMin";
import BriefMax from "../../pages/modal/BriefMax";
import Callback from "../../pages/modal/Callback";
import {IoIosArrowUp} from "@react-icons/all-files/io/IoIosArrowUp";
import {GlobalState, useSubscribe} from "../../utils/store";

export const toUpStore = new GlobalState(false);

const PanelMenu = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    function hamActive() {
        setOpenMenu(!openMenu);
    }

    const to = (index) => (e) => {
        e.preventDefault();
        if(location.path !== '/'){
            setTimeout(()=>{
                slideTo(index);
            }, 300);
            navigate('/');
        }
        slideTo(index);
    }

    const toUp = toUpStore.getState();
    useSubscribe(toUpStore);
    const buttonUp = useRef();

    useEffect(() => {
        const onScroll = (e) => {
            if (window.scrollY > 500) {
                toUpStore.setState(true)
            } else {
                toUpStore.setState(false)
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const toUpClick = (e)=>{
        if(typeof toUp === 'function') {
            toUp();
        }
    }

    return (
        <>
            <div className="aside-menu">
                <Hamburger onClick={hamActive} ham={openMenu} setHam={setOpenMenu}/>
                <div className="text">Весь сайт</div>
                <ul className="aside-menu-list">
                    <li><a href="#" onClick={to(6)}>Контакты</a></li>
                    <li><a href="#" onClick={to(5)}>Команда</a></li>
                    <li><a href="#" onClick={to(3)}>Услуги</a></li>
                    <li><a href="#" onClick={to(2)}>Кейсы</a></li>
                </ul>
            </div>

            {openMenu? <Menu openMenu={openMenu} setOpenMenu={setOpenMenu}/>:""}

            <BriefMin />
            <BriefMax />
            <Callback />

            {toUpStore.getState() ?
                <a href="#" onClick={toUpClick} ref={buttonUp} className="button-up">
                    <IoIosArrowUp />
                </a>:""
            }
        </>
    );
};

export default PanelMenu;
