import React, {useEffect, useRef, useState} from 'react';
import "./btnAnimation.scss";
import {getCoursorPointer} from "../cursor/Cursor";
import {useNavigate} from "react-router";

const BtnFull = (props) => {
    const [rip, setRip] = useState(false);
    const [btnMove, setTnMove] = useState({ x: 0, y: 0 });
    let pointer = getCoursorPointer();
    const navigate = useNavigate();

    const onMove = (e) => {
        if (!rip) {
            setTnMove({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
        }
    }

    const mouseEnter = (e) => {
        setRip(true);

        if(pointer) {
            pointer.style.width = "150px";
            pointer.style.height = "150px";
            pointer.style.transitionDuration = "0.2s";
        }
    }

    const mouseLeave = (e) => {
        setRip(false);
        if(pointer) {
            pointer.style.width = "50px";
            pointer.style.height = "50px";
            // pointer.style.transitionDuration = "0s";
        }
    }

    let onClick = props.onClick;
    if(props.to){
        onClick = ()=>{navigate(props.to)};
    }

    return (
        <button
            onClick={onClick}
            type={props.type}
            className={"btn btnFull "+props.className}
            onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}
            onMouseMove={onMove}
        >
            {rip? <span style={{left: btnMove.x+"px", top: btnMove.y+"px"}} className="ripple bg-white"/>:""}
            <span className={"text"}>{props.children}</span>
        </button>
    );
};

export default BtnFull;
