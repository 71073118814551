
const getHeaders = ()=>{
    let headers = {
        // 'Web-app': true,
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
    }

    // if(user.isAuth()){
    //     headers['Authorization'] = 'Bearer ' + user.bearer;
    // }

    return headers;
}

const baseUrl = process.env.NODE_ENV === 'development'? process.env.REACT_APP_API_URL : process.env.REACT_APP_PROD_API_URL;

const handlerResponse = (result, callback) => {
    if(result.message === 'Not authorized' || result.code === 'Not authorized'){
        // user.logout();
        return;
    }else if(result.message){
        // alertStore.setState({
        //     success: result.message
        // });
    }
    if(callback){
        callback(result);
    }
}

const handlerErrorResponse = (error, errorCallback) => {
    if(errorCallback){
        errorCallback(error);
    }
    if(error.message && !errorCallback){
        // alertStore.setState({danger: error.message});
    }
}

export function post(url, data, callback, errorCallback)
{
    fetch(baseUrl + url,{
        method: 'POST',
        headers: getHeaders(),
        credentials: "include",
        mode: 'cors',
        body: data
    }).then(res => res.json())
    .then(
        (result) => handlerResponse(result, callback),
        (error) => handlerErrorResponse(error, errorCallback)
    );
}

export function get(url, callback, errorCallback, ignoreMessage)
{
    fetch(baseUrl + url,{
        headers: getHeaders(),
        credentials: "include",
        mode: 'cors',
    }).then(res => res.json())
    .then(
        (result) => handlerResponse(result, callback),
        (error) => handlerErrorResponse(error, errorCallback)
    );
}

export function getHtml(url, callback, errorCallback)
{
    let headers = {}
    // if(user.isAuth()){
    //     headers['Authorization'] = 'Bearer ' + user.bearer;
    // }

    fetch(baseUrl + url,{
        headers: headers
    }).then(res => res.text())
        .then(
            (result) => handlerResponse(result, callback),
            (error) => handlerErrorResponse(error, errorCallback)
        );
}
