import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCube} from "swiper";
import "swiper/css";
import "swiper/css/effect-cube";
import PersonCard from "../person/PersonCard";
import ScrollAnimation from "react-animate-on-scroll";
import {employeesStore} from "../../query/employees";
import {useSubscribe} from "../../utils/store";

const Team = () => {
    const employees = employeesStore.getState();
    useSubscribe(employeesStore);

    const [selected, setSelected] = useState(0);
    const [swiperRef, setSwiperRef] = useState(null);
    const slideTo = (index) => {
        swiperRef.slideTo(index, 800);
    };

    const onChange = (e)=>{
        setSelected(e.activeIndex)
    }

    let modules = [];
    if(window.innerWidth > 992) modules.push(EffectCube);

    return (
        <div className="team-section">
            <div className="container">
                <div className="left">
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                        <h2 className="mb-50 expandedBlack">
                            <span className="text-blue">Команда профессионалов</span> которая будет работать над вашим проектом
                        </h2>
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                        {employees.map((pers, i) =>
                            <div key={pers.id} className="btn-wrap" onClick={() => slideTo(i)}>
                                <div className={selected === i?"btn active":"btn "}>{pers.office}</div>
                            </div>
                        )}
                    </ScrollAnimation>
                </div>
                <div className="right">
                    {employees.length ?
                        <Swiper
                            slidesPerView={1}
                            speed={800}
                            spaceBetween={0}
                            mousewheel={true}
                            modules={modules}
                            // effect="coverflow"
                            effect="cube"
                            className="col-12"
                            onSwiper={setSwiperRef}
                            onSlideChange={onChange}
                        >
                            {employees.map((pers, i) =>
                                <SwiperSlide key={pers.id} className="">
                                    <PersonCard pers={pers}/>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    :""}
                </div>
            </div>
        </div>
    );
};

export default Team;
