import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {employeesStore} from "../../query/employees";
import {useSubscribe} from "../../utils/store";
import {Link} from "react-router-dom";

const ApproachItem2 = (props)=>{
    const active = props.active;
    const item = props.item;
    const employees = employeesStore.getState();
    useSubscribe(employeesStore);

    const person = employees.find(p=>p.code===item.employee) || {};

    return (
        <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp" >
            <div className={!active ? "approach" : "approach brief"}>
                <div className="approach-head">
                    <span className="count">{item.count}.</span>{item.title}
                </div>
                <div className="approach-body">
                    {!active ? <div className="left">
                        <div className="img">
                            <img src={item.image} alt="" />
                        </div>
                        <div className="panel-inf">
                            <div className="panel-inf-head">
                                <div className="radial" style={{ backgroundColor: "#78E26E" }} />
                                <div className="radial" style={{ backgroundColor: "#E2C96E" }} />
                                <div className="radial" style={{ backgroundColor: "#E26E6E" }} />
                            </div>
                            <div className="men">
                                <img src={person.avatar} alt="" />
                            </div>
                            <div className="men-inf">
                                <div className="name">{person.fullname}</div>
                                <div className="desc">Ответственный за этап</div>
                                <Link to="/brief" className="text-green fz12">
                                    Нажмите, чтобы оставить заявку
                                </Link>
                            </div>
                        </div>
                    </div> : ""}
                    <div className="right">
                        <div className="text-white mb-30 fz24">Процесс:</div>
                        <div className="text-white opacity-7 mb-40 fz18">{item.description}</div>
                        {!active ? <div className="text-white mb-30 fz24">Что получаем:</div> : ""}
                        {!active ?
                            <div className="quotation-wrap">
                                {item.quotation.map((text, i) => (
                                    <div key={i} className="quotation fz18 text-white opacity-7">
                                        {text}
                                    </div>
                                ))}
                            </div> : ""
                        }
                    </div>
                </div>
            </div>
        </ScrollAnimation>
    );
}

export default ApproachItem2;
