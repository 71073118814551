import {get} from "../utils/request";
import {GlobalState} from "../utils/store";

let isLoading = false;
export const softwaresStore = new GlobalState([]);

export const getSoftwares = ()=>{
    if(isLoading) return;
    isLoading = true;
    get(
        '/technologies',
        function (response){
            isLoading = false;
            softwaresStore.setState(response);
        }
    )
}
