
export const config = {
    site: "web-is.tech",
    email: "info@web-is.tech",
    phone: '+7(995)311-20-79',
    tg: {
        name: '@Tercius',
        link: 'https://t.me/web_is_tech'
    },
    vk: 'https://vk.com/webis.tech',
    whatsap: 'https://api.whatsapp.com/send?phone=79956052079',
    organization: {
        name: 'Смирнов П.П.',
        inn: '242305087762',
        ogrip: '319762700051470',
        address: 'Казань, Петербургская улица, 52'
    },
}
