import React from 'react';
import {Modal} from "react-bootstrap";
import "./modal.scss";
import BtnFull from "../../components/animations/BtnFull";

const Success = (props) => {
    return (
        <Modal
            show={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Body>
                <h2 className="fz38 text-white expandedBlack mb-3">{props.title}</h2>
                <BtnFull onClick={props.onHide} className="btn-success btn-lg ms-auto d-block">Закрыть</BtnFull>
            </Modal.Body>
        </Modal>
    );
};

export default Success;
