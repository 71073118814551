import React from 'react';
import "./job.scss";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import {projects} from "../../query/cases";

const Job = () => {
    return (
        <div className="job-wrap">
            <div className="container">
                <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                    <h2 className="h1 expandedBlack ">Работаем с клиентами со всего мира</h2>
                </ScrollAnimation>
                <div className="col-12 d-flex mb-50 justify-content-between">
                    <ScrollAnimation animateOnce={true} delay={1} animateIn="animate__fadeInRight">
                        <div className="col-4 fz96 text-green expandedBlack">
                            3<sup className="text-white fz24 fw-Gilroy-400">страны</sup>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce={true} delay={2} animateIn="animate__fadeInRight">
                        <div className="col-4 fz96 text-green expandedBlack">
                            40<sup className="text-white fz24 fw-Gilroy-400">клиентов</sup>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce={true} delay={3} animateIn="animate__fadeInRight">
                        <div className="col-4 fz96 text-green expandedBlack">
                            82<sup className="text-white fz24 fw-Gilroy-400">проекта</sup>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
            <div className="projects">
                {projects.map((card) =>
                    <Link to="/" key={card.key} className={"projectCard " + card.class}>
                        <ScrollAnimation animateOnce={true} delay={1} animateIn="animate__fadeInUp">
                            <img src={card.image} alt="" className="projectCard-logo" />
                        </ScrollAnimation>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Job;
