import {BrowserRouter, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import Main from "./pages/main/Main";
import React, {Suspense, lazy, useEffect} from "react";
import {Routes, useLocation} from "react-router";
import "./index.scss";
import Application from "./pages/application/Application";

import Cursor from "./components/cursor/Cursor";
import "./utils/util"
import {getSoftwares} from "./query/softwares";
import {getEmployees} from "./query/employees";
import {getProjects} from "./query/cases";
import {getReviews} from "./query/reviews";
import NotFound from "./pages/errors/NotFound";
import {isTouchScreen} from "./utils/util";
import {analytics} from "./services/analytics";
import ImagePreview from "./components/ImagePreview";
import {utmStorage} from "./services/events";
import {geoStore, getGeo} from "./stores/geo";
import {useSubscribe} from "./utils/store";
import {cityIn} from "lvovich";

const Case = lazy(() => import('./pages/cases/Case'));
const Cases = lazy(() => import('./pages/cases/Cases'));
const Contacts = lazy(() => import('./pages/contacts/Contacts'));
const Web = lazy(() => import('./pages/application/Web'));
const Brief = lazy(() => import('./pages/brief/Brief'));
const Politic = lazy(() => import('./pages/politic/Politic'));

function App() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const geo = useSubscribe(geoStore);

    useEffect(() => {
        if(geo.city) {
            let city = cityIn(geo.city);
            let title = document.getElementsByTagName('title')[0];
            let metaDesc = document.querySelector('[name="description"]');
            title.innerText = `Создание сайтов в ${city}, WIT-studio, разработка сайтов под ключ  - проработка деталей.`
            metaDesc.setAttribute('content', `Создание сайтов в ${city}, WIT-studio, разработка сайтов под ключ  - проработка деталей.`);
        }
    }, [geo.city]);

    useEffect(()=>{
        getGeo();
        getSoftwares();
        getEmployees();
        getProjects();
        getReviews();
        analytics();
        if(Object.values(params).length) {
            utmStorage.set(params);
        }
    }, []);

    return (
        <BrowserRouter>
            <Suspense fallback={<div className="w-100 d-flex justify-content-center align-items-center">Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/cases" element={<Cases />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/application" element={<Application />} />
                    <Route path="/case/:caseId" element={<Case />} />
                    <Route path="/web" element={<Web />} />
                    <Route path="/brief" element={<Brief />} />
                    <Route path="/politic" element={<Politic />} />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
            {!isTouchScreen() ? <Cursor /> : ""}
            <ImagePreview />

        </BrowserRouter>


    );
}

export default App;
