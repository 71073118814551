import React, {useEffect, useRef, useState} from 'react';
import useMeasure from 'react-use-measure';
import {useTrail, animated} from '@react-spring/web';
import {ReactComponent as CoursorSvg} from '../../images/cursor-svg.svg';
import './cursor.scss';

const coursor = [];

export const getCoursor = ()=>{
    return (coursor[0]||{}).current;
}

export const getCoursorPointer = ()=>{
    return (coursor[1]||{}).current;
}

const fast = {tension: 1200, friction: 50}
const slow = {mass: 5, tension: 200, friction: 50}
const trans = (x: number, y: number) =>
    `translate(${x}px,${y}px) translate(-50%,-50%)`;

const Cursor = () => {
    const [active, setActive] = useState(!localStorage.getItem("disableCursor"));
    let body = document.querySelectorAll("body")[0];

    coursor[0] = useRef();
    coursor[1] = useRef();

    const disableCursor = ()=>{
        localStorage.setItem('disableCursor', 1);
        setActive(false);
        body.classList.add('default-cursor');
    }

    const enableCursor = ()=>{
        localStorage.removeItem('disableCursor');
        setActive(true);
        body.classList.remove('default-cursor');
    }

    const [trail, api] = useTrail(2, i => (
        {
            xy: [0, 0],
            config: i === 0 ? fast : slow,
            className: "oba"
        }
     ));

    const [ref, {left, top}] = useMeasure();
    useEffect(() => {
        if(!active) {
            body.classList.add('default-cursor');
        }

        const handleMouseMove = e => {
            api.start({xy: [e.clientX - left, e.clientY - top]})
        }
        body.addEventListener("pointermove", handleMouseMove);
        return () => body.removeEventListener("pointermove", handleMouseMove);

    }, []);

    return (
        <>
            <div className="hooksMain">
                {trail.map((props, index) =>
                    <animated.svg
                        className="oba"
                        key={index}
                        style={{transform: props.xy.to(trans), display: !index ? "none" : ""}}
                        ref={coursor[index]}
                    />
                )}
            </div>
            <div onClick={active ? disableCursor : enableCursor} className="hooksMain cursor-switcher">
                {active ? <CoursorSvg className="coursor-svg" /> : <svg className="oba"/>}
                <svg className="oba"/>
            </div>
        </>
    )
}

export default Cursor;
