import React from 'react';
import {useSpring, animated } from 'react-spring'
import {Link} from "react-router-dom";
import {RiWhatsappFill} from "@react-icons/all-files/ri/RiWhatsappFill";
import {FaTelegramPlane} from "@react-icons/all-files/fa/FaTelegramPlane";
import {FaVk} from "@react-icons/all-files/fa/FaVk";
import "./menu.scss";
import Hamburger from "../header/Hamburger";
import BtnOutline from "../animations/BtnOutline";
import LinkOutline from "../animations/LinkOutline";
import {config} from "../../query/config";
import {events} from "../../services/events";

const Menu = (props) => {
    function hamActive() {
        props.setOpenMenu(false);
    }

    const transform = useSpring({
        to: {
            transform: "translateX(0)",
            borderRadius: "0 0 0 0"
        },
        from: {
            transform: "translateX(-100%)",
            borderRadius: "0 100% 100% 100%",
        },
        config: {duration: 300},
    })
    const transformText1 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 200,
        config: {duration: 300}
    })
    const transformText2 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 300,
        config: {duration: 300}
    })
    const transformText3 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 400,
        config: {duration: 300}
    })
    const transformText4 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 500,
        config: {duration: 300}
    })
    const transformText5 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 600,
        config: {duration: 300}
    })
    const transformText6 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 700,
        config: {duration: 300}
    })
    const transformText7 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 800,
        config: {duration: 300}
    })
    const transformText8 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 900,
        config: {duration: 300}
    })
    const transformText9 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 1000,
        config: {duration: 300}
    })
    const transformText10 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 1100,
        config: {duration: 300}
    })
    const transformText11 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 1200,
        config: {duration: 300}
    })
    const transformText12 = useSpring({
        to: {transform: "translateY(0)", opacity: 1,},
        from: {transform: "translateY(100%)", opacity: 0,},
        delay: 1300,
        config: {duration: 300}
    })


    return (
        <animated.div style={transform} className="menu-wrap">
            <Hamburger onClick={hamActive} openMenu={props.openMenu} setOpenMenu={props.setOpenMenu}/>
            <div className="menu-scroll">
                <div className="menu">
                    <ul  className="col">
                        {/*<animated.li style={transformText1} className="menu-li" >*/}
                        {/*    <Link to="/" className="menu-link text-white position-relative">Команда</Link>*/}
                        {/*</animated.li>*/}
                        <animated.li style={transformText2} className="menu-li" >
                            <Link to="/" className="menu-link text-white position-relative">Главная</Link>
                        </animated.li>
                        <animated.li style={transformText2} className="menu-li" >
                            <Link to="/cases" onClick={hamActive} className="menu-link text-white position-relative">Кейсы</Link>
                        </animated.li>
                        <animated.li style={transformText3} className="menu-li" >Услуги
                            <ul>
                                <li>
                                    <Link to="/application" onClick={hamActive} className="menu-link position-relative">Разработка мобильных
                                    приложений</Link>
                                </li>
                                <li>
                                    <Link to="/web" onClick={hamActive} className="menu-link position-relative">Разработка веб-сервисов</Link>
                                </li>
                            </ul>
                        </animated.li>
                        <animated.li style={transformText4} className="menu-li" >
                            <Link to="/contacts" onClick={hamActive} className="menu-link text-white position-relative">Контакты</Link>
                        </animated.li>
                        <animated.li style={transformText4} className="menu-li" >
                            <Link to="/brief" onClick={hamActive} className="menu-link text-white position-relative">Заполнить бриф</Link>
                        </animated.li>
                    </ul>
                    <div className="col">
                        <animated.div style={transformText5} className="mb-35">
                            <a
                                onClick={events.clickPhone}
                                className="position-relative fz20 text-white mb-1 d-inline-block text-decoration-none expandedBlack"
                                href={"tel:"+config.phone}
                            >{config.phone}</a>
                            <div className="point point-green">Звоните! Консультация бесплатная</div>
                        </animated.div>
                        <animated.div style={transformText6} className="mb-35">
                            <a className="position-relative fz20 text-white mb-1 d-inline-block text-decoration-none expandedBlack"
                                  href={"mailto:"+config.email}>{config.email}</a>
                            <div className="point point-green">Отвечаем быстро</div>
                        </animated.div>
                        <animated.div style={transformText7} className="">
                            <BtnOutline to="/brief" onClick={hamActive} type="button" className="btn btn-outline-success mb-10 ">Обсудить проект</BtnOutline>
                            <div className="text-white opacity-5 fz13">У вас есть свой проект?</div>
                        </animated.div>
                    </div>
                    <div className="col">
                        <animated.div style={transformText8} className="text-white fz20 fw-Gilroy-700 mb-20">Социальные сети:</animated.div>
                        <div className="d-flex flex-wrap">
                            <animated.div style={transformText9}>
                                <LinkOutline onClick={events.clickMessenger} href={config.vk} target="_blank" className="btn btn-outline-success col-12 mb-20">
                                    <FaVk className="me-2 fz20"/>Написать во ВКонтакте
                                </LinkOutline>
                            </animated.div>
                            <animated.div style={transformText10}>
                                <LinkOutline onClick={events.clickMessenger} href={config.whatsap} target="_blank" className="btn btn-outline-success col-12 mb-20">
                                    <RiWhatsappFill className="me-2 fz20"/>Написать в WhatsApp
                                </LinkOutline>
                            </animated.div>
                            <animated.div style={transformText11}>
                                <LinkOutline onClick={events.clickMessenger} href={config.tg.link} target="_blank" className="btn btn-outline-success col-12 mb-20">
                                    <FaTelegramPlane className="me-2 fz20"/>Написать в Telegram
                                </LinkOutline>
                            </animated.div>
                        </div>
                        <animated.div style={transformText12} className="text-white opacity-5 fz15">У вас есть свой проект?</animated.div>
                    </div>
                </div>
            </div>
        </animated.div>
    );
};

export default Menu;
