import React, { useState } from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import Review from './Review';
import {reviewsStore} from "../../query/reviews";
import {useSubscribe} from "../../utils/store";

const Reviews = (props) => {
    const reviews = reviewsStore.getState().filter(r => r.type === props.type);
    useSubscribe(reviewsStore);

    const [index, setIndex] = useState(0);
    const maxIndex = reviews.length - 1;

    const next = () => {
        let newIndex = index +1;
        if (newIndex > maxIndex) {
            newIndex = 0
        }
        setIndex(newIndex);
    };

    const prev = () => {
        let newIndex = index -1;
        if (newIndex < 0) {
            newIndex = maxIndex
        }
        setIndex(newIndex);
    };

    return (
        <>
            <div className="comment-section">
                <div className="container">
                    <ScrollAnimation animateOnce={true} animateIn="animate__fadeInUp">
                        <h2 className="text-white expandedBlack mb-60">
                            {props.name}
                            <span className="text-green"> бизнес-логика, </span>
                            упакованная для клиентов
                        </h2>
                    </ScrollAnimation>
                </div>
            </div>
            <Review review={reviews[index]||[]} prev={prev} next={next} />
       </>
    )
};

export default Reviews;
