import React from 'react';
import {Link} from "react-router-dom";
import { Pagination, Navigation } from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import LinkOutline from "../animations/LinkOutline";
import ScrollAnimation from "react-animate-on-scroll";
import notebookSvg from '../../images/notebookSvg.svg';
import telephoneSvg from '../../images/telephoneSvg.svg';
import desktopSvg from '../../images/desktopSvg.svg';
import desktopSvg2 from '../../images/desktopSvg2.svg';
import notebookSvg2 from '../../images/notebookSvg2.svg';
import telephoneSvg2 from "../../images/telephoneSvg2.svg";
import {resize} from "../../utils/image";
import {isMobile} from "../../utils/util";

const allSvgs = [
    notebookSvg,
    desktopSvg2,
    telephoneSvg,
    telephoneSvg2,
    notebookSvg2,
    desktopSvg
]

const mobileSvgs = [
    telephoneSvg,
    telephoneSvg2
]

const allClasses = [
    "case-1",
    "case-2",
    "case-3",
    "case-6",
    "case-5",
    "case-4"
]

const pkClasses = ["case-1","case-2","case-4","case-5"];

const mobileClasses = ["case-3","case-6","case-3"]

const getSvg = (svgs, index) => {
    if(svgs[index]){
        return svgs[index];
    }
    return getSvg(svgs, index - svgs.length);
}

const getClass = (classes, index) => {
    if(classes[index]){
        return classes[index];
    }
    return getClass(classes, index - classes.length);
}

const Projects = (props) => {
    const anim = props.anim;
    const setAnim = props.setAnim;

    function notLeave() {
        setAnim(true);
    }
    function notEnter() {
        setAnim(false)
    }

    let classes = props.isApp ? mobileClasses : allClasses;
    let svgs = props.isApp ? mobileSvgs : allSvgs;

    return (
        <>
            <Swiper
                direction={"horizontal"}
                slidesPerView={1}
                speed={800}
                spaceBetween={12}
                mousewheel={true}
                className="mySwiper"
                pagination={{
                    type: "fraction",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    1000: {
                        enabled: false,
                        spaceBetween: 0
                    },
                }}

            >
                {props.projects.map((card, index) => {
                    let _class = getClass(classes, index);
                    let svg = getSvg(svgs, index);

                    let image = card.images.find(img=>['app','mobile'].includes(img.type));
                    if(pkClasses.includes(_class)){
                        image = card.images.find(img=>img.type==='desktop');
                    }

                    image = resize((image || {}).path, 300);

                    return (
                        <SwiperSlide key={card.id} >
                            <div id="hnfuidhfwh9e4fui" className={anim? "projectCaart hover "+_class: "projectCaart "+_class}>
                                <Link to={"/case/" + card.id} className="abs-link"/>
                                <div className="wrap-text">
                                    <ScrollAnimation animateOnce={true} duration={1} animateIn="animate__fadeInUp">
                                        <h5 className="expandedBlack fz20">{card.name}</h5>
                                        <div className="desc">{card.shortDesc}</div>
                                    </ScrollAnimation>
                                </div>
                                <div className="wrap-image">
                                    <img src={svg} alt="" className={"svg"}/>
                                    <img src={image} alt="" className={"png"}/>
                                </div>
                            </div>
                        </SwiperSlide>
                    )}
                )}
            </Swiper>
            {!props.hideButton ?
            <div className="projectCaart projectCaart-last" onMouseEnter={notEnter} onMouseLeave={notLeave}>
                <h5 className={"text-white expandedBlack mb-3"}>Еще проекты?</h5>
                <LinkOutline to="/cases" className="btn btn-outline-success">Показать еще</LinkOutline>
            </div>
            :""}
        </>
    );
};

export default Projects;
