import React, {useEffect, useRef, useState} from 'react';
import {Form} from "react-bootstrap";
import InputMask from "react-input-mask";
import "./form.scss";

const MIN_TEXTAREA_HEIGHT = 150;

const Textarea = (props) => {
    const {onChange, value, className, ...newProps} = props;
    const textarea = useRef();
    const [text, setText] = useState(value);


    const changeComment = (e) => {
        autoheight();
        setText(e.target.value);
    }

    const autoheight = () => {
        textarea.current.style.height = "inherit";
        textarea.current.style.height = `${Math.max(
            textarea.current.scrollHeight + 5,
            MIN_TEXTAREA_HEIGHT
        )}px`;
    }

    useEffect(()=>{
        autoheight();
    }, []);

    return (
        <textarea
            ref={textarea}
            onKeyUp={changeComment}
            onChange={changeComment}
            className={(className||"form-control") +  (!text ? "" : " focus")}
            {...newProps}
        >{text}</textarea>
    );
};

export default Textarea;
