import {get} from "../utils/request";
import {GlobalState} from "../utils/store";

let isLoading = false;
export const employeesStore = new GlobalState([]);

export const getEmployees = ()=>{
    if(isLoading) return;
    isLoading = true;
    get(
        '/employees',
        function (response){
            isLoading = false;
            employeesStore.setState(response);
        }
    )
}
