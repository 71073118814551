import React from 'react';
import {Link} from "react-router-dom";
import "./appBox.scss";
import ScrollAnimation from "react-animate-on-scroll";
import {useSubscribe} from "../../utils/store";
import {softwaresStore} from "../../query/softwares";
import {inArray} from "../../utils/array";

const AppBox = (props) => {
    const box = props.box;
    const technologies = softwaresStore.getState().filter(s => inArray(s.code, box.technologies))
    useSubscribe(softwaresStore);

    return (
        <div className="appBox">
            <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                <div className="fz28 mb-20 fw-Gilroy-700">{box.title}</div>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                <div className="desc">{box.desc}</div>
            </ScrollAnimation>
            <div className="appBox-logo">
                <ScrollAnimation animateOnce={true} animateIn="animate__fadeInRight">
                    {technologies.map((img) =>
                        <img key={img.code} src={img.icon} alt=""/>
                    )}
                </ScrollAnimation>
            </div>
            <div className="d-flex flex-wrap">
                {box.links.map((el, i)=>
                    <ScrollAnimation key={i} animateOnce={true} animateIn="animate__fadeInRight">
                        <a className="btn btn-outline-secondary btn-sm">
                            {el.name}
                        </a>
                    </ScrollAnimation>
                )}
            </div>
        </div>
    );
};

export default AppBox;
