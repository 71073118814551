import {call} from "../utils/functions";

export const analytics = ()=>{
    call(yandexMetrics);
    call(googleTagManager);
}

export const yandexMetricNumber = 90311478;

const yandexMetrics = () => {
    (function (m, e, t, r, i, k, a) {
        m[i] = m[i] || function () {
            (m[i].a = m[i].a || []).push(arguments)
        };
        m[i].l = 1 * new Date();
        for (var j = 0; j < document.scripts.length; j++) {
            if (document.scripts[j].src === r) {
                return;
            }
        }
        k = e.createElement(t);
        a = e.getElementsByTagName(t)[0];
        k.async = 1;
        k.src = r;
        a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    window.ym(yandexMetricNumber, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trackHash: true
    });
}

const googleTagManager = ()=>{
    let s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://www.googletagmanager.com/gtag/js?id=UA-248362609-1';
    s.async = true;
    document.body.appendChild(s);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function (){window.dataLayer.push(arguments);}

    window.gtag('js', new Date());

    window.gtag('config', 'UA-248362609-1');
}
