import React, {useEffect} from 'react';

const HistoryScroll = ()=> {
    useEffect(()=>{
        window.scroll({top: 0, behavior: 'smooth'})
    }, [window.location.href]);

    return '';
}

export default HistoryScroll;