import React from 'react';

const Hamburger = (props) => {
    let openMenu = props.openMenu;
    return (
        <button type="button" className={openMenu? "hamburger active":"hamburger"} onClick={props.onClick}>
            <div className="hamburger-line top"/>
            <div className="hamburger-line center"/>
            <div className="hamburger-line bottom"/>
        </button>
    );
};

export default Hamburger;
