export const faq = [
    {
        key: 1,
        question: "Что мы получим, заказав разработку \"под ключ\"?",
        answer: "ТЗ - мы сами сформулируем этапы реализации, вам будет удобно контролировать процесс, УНИКАЛЬНЫЙ ДИЗАЙН (если у вас нет логотипа/брендбука - мы решаем эту задачу), АДАПТИВНАЯ ВЕРСТКА - сайт или приложение будет красиво смотреться на ПК, смартфоне или планшете, ЧИСТЫЙ КОД - легко масштабировать, прикручивать новый функционал, ИНТЕГРАЦИЯ С ВНЕШНИМИ СЕРВИСАМИ - мессенджеры, любое API, CRM-система, онлайн-оплата, ДОМЕН И ХОСТИНГ - предложим понятное название адреса сайта и оптимальный тариф, CMS-система - вы сами сможете изменять/добавлять контент, ПОДДЕРЖКА - всегда на связи по любым вопросам."
    },
    {
        key: 2,
        question: "Мне нужен онлайн магазин продуктов - сроки реализации?",
        answer: "Стандартный интернет-магазин с нуля - это 1-2 месяца работы. Если у вас есть четкое ТЗ, дизайн или разработчик, готовый интегрироваться в нашу команду для совместной работы, - сроки будут меньше. Если вам нужен особенный функционал, например, разработка уникальной системы начисления бонусов - сроки увеличатся."
    },
    {
        key: 3,
        question: "Если я захочу изменить что-то в приложении - как это сделать?",
        answer: "Каждый проект, который мы разрабатываем, включает систему управления: админ-панель, личный кабинет или планформа-шаблон. Мы обязательно знакомим заказчика с возможностями управления и поддерживаем связь после запуска в продакшен. Изменение контента, расширение прав выбранных пользователей, скрытие конкретных модулей приложения - вам будет удобно работать с этим."
    },
    {
        key: 4,
        question: "Можно ли добавить или изменить функционал сайта спустя какое-то время его работы?",
        answer: "Безусловно, да. Мы используем гибкие готовые движки или пишем свой уникальный - в любом случае, прикрутить/изменить функционал реально в любое время. Вы можете обратиться к нам или воспользоваться услугами другой организации, чтобы внести необходимые дополнения."
    }
]
