import React from 'react';
import {Link} from "react-router-dom";
import ServSvg1 from "./ServSvg1";
import ServSvg2 from "./ServSvg2";
import "./services.scss";

const Services = () => {
    return (
        <div className="services-wrap">
            <div className="container">
                <h2 className="h1 expandedBlack">Услуги</h2>
            </div>
            <div className="services">
                <div className="service">
                    <Link to="/application" className="abs-link"/>
                    <div className="service-text">
                        <h3 className="mb-30 expandedBlack">Разработка мобильных приложений</h3>
                        <Link to="/application" className="text-white fz16 text-decoration-none">Перейти »</Link>
                    </div>
                    <ServSvg1 className="servSvg"/>
                </div>
                <div className="service">
                    <Link to="/web" className="abs-link"/>
                    <div className="service-text">
                        <h3 className="mb-30 expandedBlack">Разработка сайтов</h3>
                        <Link to="/web" className="text-white fz16 text-decoration-none">Перейти »</Link>
                    </div>
                    <ServSvg2 className="servSvg"/>
                </div>
            </div>
        </div>
    );
};

export default Services;