NodeList.prototype.map = Array.prototype.map;
HTMLCollection.prototype.map = Array.prototype.map;

export const clone = (el) => {
    if(!el) return null;
    return JSON.parse(JSON.stringify(el));
}

export const getTimeFromMins = (mins) => {
    let hours = Math.trunc(mins/60);
    let minutes = mins % 60;
    return [hours, minutes];
};

export const parseTime = (string) => {
    if(!string) return 0;
    let time = string.split(':');
    return (time[0]*60) + parseInt(time[1]);
}

export const formatToHour = (date) => {
    let h = date.getHours();
    let m = date.getMinutes();
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return h + ':' + m;
}

export const parseDate = (isoString) => {
    if(!isoString) return new Date();
    if(typeof isoString === 'number') return new Date(isoString);
    if(typeof isoString === 'string') isoString = isoString.split('Z')[0];
    if(isSafari) return parseDateSafari(isoString);
    return new Date(isoString);
}

export function parseDateSafari(s) {
    let dateTime = s.split('T');
    if(!dateTime[1]) dateTime = s.split(' ');
    let dateBits = dateTime[0].split('-');
    let timeBits = [0,0,0];
    if(dateTime[1]) {
        timeBits = dateTime[1].split(':');
    }
    return new Date(dateBits[0], parseInt(dateBits[1]) - 1, dateBits[2], timeBits[0], timeBits[1], timeBits[2]);
}

export const numberFormat = ( number, decimals, dec_point, thousands_sep ) => {

    let i, j, kw, kd, km;

    // input sanitation & defaults
    if( isNaN(decimals = Math.abs(decimals)) ){
        decimals = 0;
    }

    if( dec_point === undefined ){
        dec_point = ".";
    }
    if( thousands_sep === undefined ){
        thousands_sep = " ";
    }

    i = parseInt(number = (+number || 0).toFixed(decimals)) + "";

    if( (j = i.length) > 3 ){
        j = j % 3;
    } else{
        j = 0;
    }

    km = (j ? i.substr(0, j) + thousands_sep : "");
    kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sep);
    kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

    return km + kw + kd;
}

export function plural(n) {
    let args = [...arguments];
    args.shift();
    let idx;
    if (n % 10 === 1 && n % 100 !== 11) {
        idx = 0; // many
    } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
        idx = 1; // few
    } else {
        idx = 2; // one
    }
    return args[idx] || args[idx-1] || args[idx-2];
}

export const randomString = (length) => {
    let abc = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM";
    let random = abc[Math.floor(Math.random() * abc.length)];
    let newAbc = "";
    while (newAbc.length < length) {
        newAbc += random;
        random = abc[Math.floor(Math.random() * abc.length)];
    }
    return newAbc;
}

export const checkIOS = () => {
    let isIOS = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);


    let isIOS2 = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    return isIOS || isIOS2;
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isObject = (el)=>{
    return typeof el === 'object';
}

export const scrollToTarget = (el, offset)=>{
    let elementPosition = el.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

export const mobileScrollToTarget = (el, offset)=>{
    let container = document.querySelector('#root > .container');
    if(!container) return;
    container.scrollTo({ top: el.offsetTop - 100, behavior: "smooth" });
}

export const isTouchScreen = ()=>{
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

export const isMobile = window.outerWidth < 768;
