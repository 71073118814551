import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import "./form.scss";

const FormControl = (props) => {
    const {onChange, value, className, type, ...newProps} = props;
    const [text, setValue] = useState(value || "");
    function changeText(e) {
        e.preventDefault();
        setValue(e.target.value)
    }

    return (
        <Form.Control
            value={text}
            onChange={changeText}
            className={className + (!text ? "" : " focus")}
            type={type || "text"}
            {...newProps}
        />
    );
};

export default FormControl;
